<template>
  <div class="main register_signup_main_app">
    <div v-show="toggle">
      <v-form ref="form" v-model="valid">
        <v-dialog content-class="vdialog_disagree" v-model="whenDisagreeDialog">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content text-center">
              <div class="modal-body p-20">
                <h6 class="mt-8">
                  약관에 동의하지 않을 경우,<br />
                  Gilead Korea Medical Information에서 제공하는 정보를 확인하실
                  수 없습니다.
                </h6>
                <br />

                <p class="mt-20">
                  확인을 클릭하여 로그인 페이지로 돌아가거나, <br />
                  동의여부를 수정 해주시기 바랍니다.
                </p>
              </div>
              <div class="modal-footer py-10 px-4">
                <v-btn
                  class="modal-footer-btn mr-2"
                  color="info"
                  rounded
                  @click="cancelTerms"
                >
                  확인
                </v-btn>
                <v-btn
                  class="modal-footer-btn"
                  color="primary"
                  rounded
                  @click="whenDisagreeDialog = false"
                >
                  동의여부 수정하기
                </v-btn>
              </div>
            </div>
          </div>
        </v-dialog>
        <v-dialog
          content-class="vdialog_terms"
          v-model="viewTermsandConditionsdialog"
          style="width: 50% !important"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="text-end">
                <v-btn
                  class="text-end"
                  style="margin-left: auto"
                  icon
                  @click="viewTermsandConditionsdialog = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>

              <div
                class="modal-header login_dialog_modal_header_second text-center"
              >
                <h5 class="modal-title" id="staticBackdropLabel">이용약관</h5>
              </div>
              <div class="modal-body p-20">
                <p>
                  아래 이용약관(이하 “본 약관”이라 합니다)은 길리어드 사이언스
                  코리아 유한회사 (이하 “Gilead”라 합니다) 및 그 계열회사 및
                  양수인 등이 운영하는 본 Gilead Korea Medical Information
                  웹사이트 (이하 “Gilead 웹사이트”라 합니다)에 대한 귀하의 사용
                  및 귀하가 이를 신뢰하여 하는 행위에 적용됩니다.<br /><br />

                  <b>이용약관</b><br />

                  본 Gilead 웹사이트에 접속하고 본 Gilead 웹사이트를 사용하는
                  행위에는 본 약관에서 명시하는 조건 및 모든 관계 법령, 규칙
                  및/또는 규정이 적용됩니다. 본 Gilead 웹사이트는 한국 내의
                  이용자들을 위해 제작되었습니다. 귀하는 본 Gilead 웹사이트에
                  접속하거나 이를 이용함으로써 본 약관에 동의하고 귀하가 만19세
                  이상임을 진술한 것으로 간주됩니다. Gilead가 약관을 개정할
                  경우에는 적용일자 및 개정사유를 명시하여 그 적용일자 7일
                  이전부터 적용일자 전일까지 통지합니다. 다만, 이용자에게
                  불리하게 약관 내용을 변경하는 경우에는 최소한 30일 이상의 사전
                  유예기간을 두고 통지합니다. Gilead가 이에 따라 변경된 약관을
                  통지하면서 변경된 약관의 적용일자 이전까지 거부의 의사를
                  표시하지 않았을 때에는 약관의 변경에 대해 동의하는 것으로
                  간주한다는 뜻을 명확하게 공지하였음에도, 위 기간 내 거부의
                  의사를 표시하지 아니한 경우 이용자는 약관의 변경에 동의한
                  것으로 간주합니다. <br /><br />

                  <b>회원의 정의, 가입대상 등</b><br />

                  회원이란 의사, 간호사 및 약사 면허가 있으며, Gilead 웹사이트
                  이용을 신청하고 Gilead가 회원으로 적합하다고 인정하는
                  일반개인으로 본 약관에 동의하고 서비스의 회원 가입양식을
                  작성하고 ID와 비밀번호를 발급받은 사람을 말합니다.<br /><br />

                  <b>Gilead 웹사이트 가입의 성립</b><br />

                  1. 가입은 가입 희망자의 이용 신청에 대한 Gilead의 이용 승낙과
                  이용자의 약관 내용에 대한 동의로 성립됩니다. <br />

                  2. 가입 희망자는 Gilead에서 요청하는 최소한의 개인 신상정보를
                  제공해야 합니다. <br />

                  3. 가입할 때 입력한 ID는 변경할 수 없으며, 한 사람에게 오직 한
                  개의 ID가 발급됩니다.<br /><br />

                  <b>가입 승낙의 제한</b><br />

                  다른 사람의 명의를 사용하여 신청하였을 때, 실명으로 신청하지
                  않았을 때, 신청서의 내용을 허위로 작성하였을 때, 서비스의
                  정상적인 제공을 저해하거나 타 회원의 서비스 이용에 상당한
                  지장을 줄 것으로 예상될 때, Gilead는 가입승낙을 거절할 수
                  있습니다.<br /><br />

                  <b>Gilead 웹사이트 서비스 제공 및 변경</b><br />

                  Gilead는 등록한 회원에게 다음과 같은 서비스를 제공합니다.
                  <u
                    >서비스의 내용은 관계 법령 및 Gilead의 정책에 따라
                    변경되거나 종료될 수 있으며,</u
                  >
                  Gilead는 서비스 내용이 변경되거나 서비스가 종료되는 경우
                  회원의 등록된 이메일 주소로 서비스 내용의 변경 또는 종료를
                  통지할 수 있습니다.<br />

                  - Gilead 웹사이트 회원가입 및 관리<br />

                  - Gilead 제품에 대한 의학정보 전달, 질의응답을 포함한 회사
                  제품에 대한 의학정보 문의 대응 및 관련 의학정보 전달 등<br /><br />

                  Gilead는 컴퓨터 등 정보통신설비의 보수점검∙교체 및 고장,
                  통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을
                  일시적으로 중단할 수 있습니다. <br /><br />

                  <b>Gilead 웹사이트 이용제한 및 중지</b><br />

                  Gilead는 다음 사항에 해당하는 경우, 예고 없이 이용 계약을
                  해지하거나 기간을 정하여 회원의 서비스 이용을 중지할 수 있으며
                  그에 따른 책임을 지지 않습니다. 다음 사항의 규정에 의하여
                  서비스의 이용을 금지 당한 회원은 그 이용 금지에 대하여 이의가
                  있을 경우 사유를 명시하여 서면으로 Gilead에 이의 신청을 해야
                  합니다. 이 경우 Gilead는 이의 타당성 등을 확인 및 검토한 후
                  결과를 해당 회원에게 통지합니다.<br />
                  1. 타인의 ID 및 비밀번호를 도용한 경우 <br />
                  2. 신청서의 내용을 허위로 기재하거나 허위서류를 첨부한 때<br />
                  3. 부적격한 회원(비보건의료전문가 등)으로 판명될 때 <br />
                  4. 서비스가 범죄적 행위에 사용될 때 <br />
                  5. 타 회원의 서비스 이용에 심각한 장애를 일으켰을 때<br />
                  6. 같은 회원이 다른 ID로 이중 등록을 한 경우 <br />
                  7. 전시, 사변, 천재 지변 또는 이에 준하는 국가 비상사태가
                  발생하거나 발생할 우려가 있는 경우<br />
                  8. 정기 점검이나 전용선의 장애 등 부득이한 사유가 발생하여
                  Gilead가 서비스의 제공을 제한 또는 중지할 필요가 있다고
                  판단하는 경우 <br />
                  9. 기타 관련 법령이나 Gilead가 정한 이용조건에 위배되는
                  경우<br /><br />

                  <b>회원 ID 및 비밀번호 관리</b><br />

                  1.
                  <u>
                    회원의 ID와 비밀번호에 관한 모든 관리의 책임은 회원에게
                    있습니다.
                  </u>
                  <br />

                  2.
                  <u>
                    자신의 ID가 부정하게 사용된 경우, 회원은 반드시 Gilead에 그
                    사실을 통보해야 합니다.
                  </u>
                  <br />

                  3. Gilead는 개인의 신분 확인이 가능한 정보를 회원의 사전 허락
                  없이 Gilead와 관계가 없는 제 3 자에게 팔거나 제공하지
                  않습니다.<br /><br />

                  <b>Gilead 웹사이트 유지 보수</b><br />

                  양질의 서비스 제공을 위하여 Gilead는 서비스의 유지보수, 정기
                  점검 등을 실시합니다. 또한 Gilead의 판단 하에 시스템의
                  전반적인 보수, 업그레이드 등을 위하여 서비스 사용이 일시
                  중단될 수 있습니다.<br /><br />

                  <b>진술보장의 부인 및 책임 제한</b><br />

                  <u>
                    Gilead는 본 Gilead 웹사이트를 인터넷 커뮤니티를 위한 서비스
                    차원에서 운영합니다. 본 Gilead 웹사이트는 본 웹사이트에
                    게시된 사안에 관하여 일반적인 정보를 제공할 목적으로
                    만들어진 웹사이트입니다. 본 웹사이트를 통하여 의ㆍ약학적
                    정보가 제공되는 경우에도, 회원은 Gilead가 특정한 사안에 대한
                    직접적인 의학적 의견을 주는 것이 아니라는 점을 충분히
                    이해하며, 제공된 자료는 그 스스로의 전문지식을 바탕으로
                    참고로만 이용되어야 합니다. Gilead는 본 Gilead 웹사이트의
                    이용과 관련하여 관련 법령에 특별한 규정이 없는 한 책임을
                    지지 않으며, Gilead의 고의 또는 중대한 과실이 있는 경우를
                    제외하고는 본 Gilead 웹사이트의 이용과 관련하여 발생한
                    회원의 손해에 대하여 어떠한 책임도 부담하지 않습니다.
                  </u>
                  <br /><br />

                  구체적으로, Gilead는 본 Gilead 웹사이트에 정확하고 가장 최신의
                  정보를 제공하기 위하여 합리적인 노력을 다할 것이나, 모든
                  정보는 “있는 그대로” 제공됩니다. Gilead는 본 Gilead 웹사이트
                  상에 제시된 정보의 정확성에 관하여 어떠한 진술이나 보장도
                  제공하지 않으며, 관계 법률상 허용되는 한도 내에서 최대한
                  상품성, 특정 목적에의 적합성 및 비침해성에 관한 보장을
                  비롯하여, 어떠한 명시적∙묵시적 진술∙보장도 제공하지 않습니다.
                  또한, 제3자에 의하여 제공되는 정보의 경우 Gilead와 견해가 다를
                  수 있으며 Gilead는 이에 대하여 책임을 부담하지 않습니다.<br /><br />

                  또한, 본 Gilead 웹사이트에서 특정 일자 기준으로 제공되는
                  정보는 해당 일자 기준으로만 정확한 것일 수 있으며, Gilead는
                  해당 정보를 업데이트하여야 할 어떠한 책임도 부담하지 않습니다.
                  Gilead는 본 Gilead 웹사이트에 게시된 정보를 근거로 하는 어떠한
                  행동에 대하여도 책임을 부담하지 않으며, 본 Gilead 웹사이트를
                  사용하는 모든 사용자들은 각자의 위험 부담에 의하여 본 Gilead
                  웹사이트 및 본 Gilead 웹사이트에 게시된 콘텐츠 일체에 접속하고
                  이를 사용하는 것에 동의합니다.<br /><br />

                  <u>
                    Gilead, Gilead 측 대리인 및/또는 그 계열회사는, 사용자의 본
                    Gilead 웹사이트 접속 및 사용이나 사용불가, 또는 본 Gilead
                    웹사이트에 게시된 콘텐츠 상의 오류나 누락으로부터 발생하는
                    모든 직접 손해, 부수적 손해, 결과적 손해, 간접 손해 또는
                    징벌적 손해에 대하여 어떠한 책임도 부담하지 않습니다.
                  </u>
                  <br /><br />

                  귀하가 본 Gilead 웹사이트 또는 인터넷 전자우편을 통해 Gilead
                  로 전송하는 모든 의사연락이나 자료는 기밀유지에 대한 의무 부과
                  없이 전송하는 것으로 간주됩니다. Gilead는 복제, 출판, 방송 및
                  게시 등 목적을 불문하고 전술한 의사연락이나 자료를 사용하지
                  않을 것임을 명시적∙묵시적으로 확약하지 않습니다.<br /><br />

                  <b>건강관련 정보</b><br />

                  본 Gilead 웹사이트는 의학적 조언을 제공하지 않고 의학적 조언을
                  제공하는 것을 목적으로 하지 않으며, Gilead 및 Gilead의
                  계열회사, 관계사 또는 라이센서나 합작투자 파트너들이
                  생산하거나 공급하는 제품의 올바른 사용지침을 제공하는 것은
                  아닙니다. 본 Gilead 웹사이트상에 게시된, 품목허가된 제품에
                  관한 정보는 전적으로 특정 제품에 관한 한국 식품의약품안전처의
                  품목허가 사항을 기준으로 한 것입니다. <br /><br />

                  <b>지식재산: 저작권 & 상표권</b><br />

                  달리 명시하지 않는 한, Gilead 회사명, 로고 및 모든 제품명은,
                  활자 크기 또는 상표 기호 유무와 관계없이, Gilead 및 Gilead의
                  계열회사, 관계사들 또는 라이센서나 합작투자 파트너들의
                  상표입니다. Gilead의 서면 승인 없이 전술한 상표를 사용, 복제,
                  복사 또는 재배포하는 행위는 금지됩니다.<br /><br />

                  모든 이미지와 텍스트를 포함, 본 Gilead 웹사이트상의 모든
                  콘텐츠는 저작권에 따라 보호됩니다. 귀하가 본 약관에 동의하시는
                  것에 대한 반대 급부로, Gilead는 귀하에게 본 Gilead 웹사이트에
                  접속하고 사용할 수 있는 개인적, 비독점적, 비양도적 라이센스를
                  허여합니다. 귀하는 오로지 개인적이고 비상업적인 목적으로만 본
                  Gilead 웹사이트에서 자료를 다운로드할 수 있으며, Gilead의
                  명시적인 서면 동의 없이는 본 Gilead 웹사이트를 복제, 배포,
                  다운로드, 변경, 재사용, 재포스팅하거나 달리 유용할 수
                  없습니다. Gilead가 소유한 지식재산권을 법률상 허용되는 최대한
                  집행하는 것이 Gilead의 방침입니다. 본 약관에서 허용하는 경우를
                  제외하고, 관련 상표, 저작권 또는 기타 자료를 사용 또는
                  오용하는 행위는 명시적으로 금지되며, 전술한 행위는 저작권법,
                  상표법, 통신 관련 규정/법규 및 기타 법률, 법규 및/또는 규정에
                  따른 위반행위에 해당할 수 있습니다.<br /><br />

                  <b>제3자 웹사이트</b><br />

                  Gilead가 운영하는 웹사이트는 제3자들이 운영 또는 관리하는
                  웹사이트로 연결되는 하이퍼링크를 포함할 수 있습니다. Gilead는
                  제3자 웹사이트 상에서 제공되는 제품이나 서비스의 내용 또는
                  사용에 대한 책임을 부담하지 않으며, 이를 정기적으로 모니터링,
                  승인, 검토 또는 보증하지 않습니다. Gileads는 본 웹사이트에
                  링크된 제3자 웹사이트 등의 이용, 본 웹사이트 회원 상호간 및
                  회원과 제3자 상호 간에 본 웹사이트를 통하여 제공되는 서비스를
                  매개로 발생한 분쟁에 대해 개입할 의무가 없으며, 이로 인한
                  손해에 대하여 책임을 지지 않습니다. Gilead는 다른 회원의
                  행위로 인하여 입게 되는 회원의 손해(정신적 손해 포함)에
                  대하여는 일체의 책임을 지지 않습니다.<br /><br />

                  <b>당사 연락처</b><br />

                  길리어드 사이언스 코리아 유한회사<br />

                  Attention: Medical Information Department<br />

                  서울특별시 중구 을지로5길 26, 센터원빌딩 서관 15층<br /><br />
                </p>
                <v-radio-group
                  v-model="member.termsAndConditions"
                  @change="uncheckall($event)"
                >
                  <v-radio
                    color="black"
                    :label="$t('register.agreeTermsAndConditions')"
                    :value="true"
                  >
                  </v-radio>
                  <v-radio
                    color="black"
                    :label="$t('register.disagree')"
                    :value="false"
                  >
                  </v-radio>
                </v-radio-group>
              </div>
              <div class="modal-footer pa-10">
                <v-btn
                  class="modal-footer-btn"
                  color="primary"
                  rounded
                  :disabled="!member.termsAndConditions"
                  @click="viewTermsandConditionsdialog = false"
                >
                  확인
                </v-btn>
              </div>
            </div>
          </div>
        </v-dialog>

        <!-- <v-dialog
          content-class="vdialog_terms"
          v-model="viewProcesstoPersonalInformationdialog"
          style="width: 50% !important"
        >
          <div
            class="modal-dialog modal-dialog-centered"
            style="background-color: white !important"
          >
            <div class="modal-content">
              <div class="text-end">
                <v-btn
                  class="text-end cross_btn_for_pdf"
                  style="margin-left: auto"
                  icon
                  @click="viewProcesstoPersonalInformationdialog = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
              <div
                class="
                  modal-header
                  login_dialog_modal_header_second
                  text-center
                "
              >
                <h5 class="modal-title" id="staticBackdropLabel">
                  개인정보 처리에 관한 동의서
                </h5>
              </div>
              <div class="modal-body p-20">
                <p>
                  길리어드 사이언스 코리아 유한회사(이하 “회사”)는 아래와 같이
                  귀하의 개인정보를 처리(수집, 이용, 제공 등)합니다. 아래 사항을
                  충분히 읽어보시고, 각 사항에 동의하여 주시기 바랍니다.
                </p>
                <br />
                <h6>1. 개인정보의 수집 및 이용에 대한 동의</h6>
                <table>
                  <tr>
                    <td colspan="2">필수적 수집 및 이용</td>
                  </tr>
                  <tr>
                    <td class="table_td_blue text-center" width="20%">
                      <b>수집항목</b>
                    </td>
                    <td width="80%">
                      ◦ 성명<br />
                      ◦ 이메일 주소, 비밀번호, 회원가입일 <br />◦ (의사, 간호사,
                      약사인 경우) 직업 및 면허번호<br />
                      ◦ 생년월일 <br />◦ 생성정보 수집 툴을 통한 수집(접속로그,
                      접속 IP 정보, 마지막 로그인 일시 등), 서비스 이용
                      내역(검색 기록, 브라우징 기록 등) <br />◦ Gilead Korea
                      Medical Information Website (“본 웹사이트”) 서비스 이용,
                      회사와의 교류 및 연락 과정에서 수집∙확인된
                      정보(제품∙서비스에 대한 귀하의 의견·평가∙반응, 민원∙문의
                      및 응대 내역, 설문조사 답변 등 포함)
                    </td>
                  </tr>
                  <tr>
                    <td class="table_td_blue text-center" width="20%">
                      <b>수집 및 이용목적 </b>
                    </td>
                    <td width="80%">
                      ◦ 본 웹사이트 회원등록 및 관리<br />
                      ◦ 본 웹사이트를 통한 서비스 제공: 회사 제품에 대한
                      의학정보 전달, 질의응답을 포함한 회사 제품에 대한 의학정보
                      문의 대응 및 관련 의학정보 전달<br />
                      ◦ 본인 여부 및 가입자격(보건의료전문가) 확인 <br />◦
                      중복가입·부정이용·비인가사용 등의 방지<br />
                      ◦ 민원/요청사항 상담 및 처리, 분쟁 대응 등 회사와의 제반
                      커뮤니케이션, 회사 제공 정보에 대한 피드백 확인 및
                      제품∙서비스에 대한 불만처리기록 보관 등 회원 분석, 데이터
                      분석, 통계 및 제품 서비스 개선 활동, 관련 증빙, 기타 위
                      목적에 부대되는 회사의 업무<br />
                      ◦ 회사의 의무 이행 및 관련 법령과 내부 규정 준수
                    </td>
                  </tr>
                  <tr>
                    <td class="table_td_blue text-center" width="20%">
                      <b>보유 및 이용기간</b>
                    </td>
                    <td width="80%">
                      관계 법령의 규정에 따라 귀하의 개인정보를 보존할 의무가
                      있는 경우가 아닌 한, 회사는
                      <u class="highlight">회원 탈퇴 시까지</u> 귀하의
                      개인정보를 보유 및 이용합니다.
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      귀하는 위와 같은 개인정보의 수집 및 이용을 거부할 수
                      있습니다. 다만, 이에 동의하지 않을 경우 보건의료전문가
                      인증 및본 웹사이트<u class="red-text">의 일부 기능</u>
                      이용이 불가능할 수 있습니다.
                    </td>
                  </tr>
                </table>
                <br />
                <br />
                <h6>2. 개인정보의 제3자 제공에 대한 동의</h6>
                <table>
                  <tr>
                    <td colspan="5">필수적 제공</td>
                  </tr>
                  <tr class="table_td_blue text-center">
                    <td><b> 제공받는 자 (연락처)</b></td>
                    <td><b>제공받는 자가 소재하는 국가</b></td>
                    <td><b>제공받는 자의 개인정보 이용목적 </b></td>
                    <td><b>제공하는 개인정보의 항목</b></td>
                    <td><b> 제공받는 자의 개인정보 보유 및 이용기간</b></td>
                  </tr>
                  <tr>
                    <td>
                      <u class="highlight"
                        >Gilead Sciences Inc. 및 계열회사1 (“길리어드 그룹 사”)
                      </u>
                    </td>
                    <td>
                      Gilead Sciences Inc.: 미국 계열회사: 아일랜드, 한국 외
                      40여개 국
                    </td>
                    <td>
                      <u class="highlight"
                        >귀하가 위 1항을 통해 수집 및 이용에 동의한 목적 일체</u
                      >
                    </td>
                    <td>
                      귀하가 위 1항을 통해 수집 및 이용에 동의한 개인정보 일체
                    </td>
                    <td>
                      <u class="highlight"
                        >관계법령 규정에 따라 개인정보를 보존하여야 하는 경우가
                        아닌 한, 탈퇴 시까지</u
                      >
                    </td>
                  </tr>
                  <tr>
                    <td colspan="5">
                      <p>
                        <u class="red-text">
                          (길리어드 그룹사 현황은
                          <a
                            href="http://www.gilead.com/about/worldwide-operations"
                            >http://www.gilead.com/about/worldwide-operations</a
                          >에서도 확인하실 수 있습니다. Global Operations에서 각
                          지역으로 들어가면 해당 나라에 대한 관련 계열사의
                          구체적인 주소 및 연락처를 보실 수 있습니다.)
                        </u>
                      </p>

                      귀하는 위와 같은 개인정보의 제3자 제공을 거부할 수
                      있습니다. 다만, 이에 동의하지 않을 경우 보건의료전문가
                      인증 및 본 웹사이트<u class="red-text">의 일부 기능</u>
                      이용이 불가능할 수 있습니다.
                    </td>
                  </tr>
                </table>
                <br /><br />

                <h6>3. 개인정보의 국외 이전에 대한 동의</h6>

                <table>
                  <tr class="table_td_blue text-center">
                    <td>
                      <b>이전받는 자 (연락처)</b>
                    </td>
                    <td><b>이전받는 자가 소재하는 국가</b></td>
                    <td><b>이전받는 자의 개인정보 이용목적</b></td>
                    <td><b>이전하는 개인정보의 항목 </b></td>
                    <td><b> 이전일시 /방법</b></td>
                    <td><b>이전받는 자의 개인정보 보유 및 이용기간 </b></td>
                  </tr>
                  <tr>
                    <td>
                      <u class="highlight">
                        Gilead Sciences Inc. 및 계열회사 (“길리어드 그 룹사”)
                      </u>
                    </td>
                    <td>
                      Gilead Sciences Inc.: 미국 계열회사: 아일랜드, 한국 외
                      40여개 국
                    </td>
                    <td>
                      <u class="highlight"
                        >귀하가 위 1항을 통해 수집 및 이용에 동의한 목적 일체
                      </u>
                    </td>
                    <td>
                      귀하가 위 1항을 통해 수집 및 이용에 동의한 개인정보 일체
                    </td>
                    <td>정보통신망을 통해 필요한 경우 수시로 이전</td>
                    <td>
                      <u class="highlight"
                        >관계법령 규정에 따라 개인정보를 보존하여야 하는 경우가
                        아닌 한, 탈퇴 시까지</u
                      >
                    </td>
                  </tr>
                  <tr>
                    <td colspan="6">
                      귀하는 위와 같은 개인정보의 국외 이전을 거부할 수
                      있습니다. 다만, 이에 동의하지 않을 경우 보건의료전문가
                      인증 및 본 웹사이트 이용이 불가능할 수 있습니다.
                    </td>
                  </tr>
                </table>

                <br /><br />
              </div>
            </div>
          </div>
        </v-dialog> -->
        <v-dialog
          content-class="vdialog_terms"
          v-model="viewProcesstoPersonalInformationUseDialog"
          style="width: 50% !important"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="text-end">
                <v-btn
                  class="text-end"
                  style="margin-left: auto"
                  icon
                  @click="viewProcesstoPersonalInformationUseDialog = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
              <div
                class="modal-header login_dialog_modal_header_second text-center"
              >
                <h5 class="modal-title" id="staticBackdropLabel">
                  1. 개인정보의 수집 및 이용에 대한 동의
                </h5>
              </div>
              <div class="modal-body p-20">
                <p>
                  길리어드 사이언스 코리아 유한회사(이하 “회사”)는 아래와 같이
                  귀하의 개인정보를 처리(수집, 이용, 제공 등)합니다. 아래 사항을
                  충분히 읽어보시고, 각 사항에 동의하여 주시기 바랍니다.
                </p>
                <br />
                <!-- <h6>1. 개인정보의 수집 및 이용에 대한 동의</h6> -->
                <table>
                  <tr>
                    <td colspan="2">필수적 수집 및 이용</td>
                  </tr>
                  <tr>
                    <td class="table_td_blue text-center" width="20%">
                      <b>수집항목</b>
                    </td>
                    <td width="80%">
                      ◦ 성명<br />
                      ◦ 이메일 주소, 비밀번호, 회원가입일 <br />◦ (의사, 간호사,
                      약사인 경우) 직업 및 면허번호<br />
                      ◦ 생년월일 <br />◦ 생성정보 수집 툴을 통한 수집(접속로그,
                      접속 IP 정보, 마지막 로그인 일시 등), 서비스 이용
                      내역(검색 기록, 브라우징 기록 등) <br />◦ Gilead Korea
                      Medical Information Website (“본 웹사이트”) 서비스 이용,
                      회사와의 교류 및 연락 과정에서 수집∙확인된
                      정보(제품∙서비스에 대한 귀하의 의견·평가∙반응, 민원∙문의
                      및 응대 내역, 설문조사 답변 등 포함)
                    </td>
                  </tr>
                  <tr>
                    <td class="table_td_blue text-center" width="20%">
                      <b>수집 및 이용목적 </b>
                    </td>
                    <td width="80%">
                      ◦ 본 웹사이트 회원등록 및 관리<br />
                      ◦ 본 웹사이트를 통한 서비스 제공: 회사 제품에 대한
                      의학정보 전달, 질의응답을 포함한 회사 제품에 대한 의학정보
                      문의 대응 및 관련 의학정보 전달<br />
                      ◦ 본인 여부 및 가입자격(보건의료전문가) 확인 <br />◦
                      중복가입·부정이용·비인가사용 등의 방지<br />
                      ◦ 민원/요청사항 상담 및 처리, 분쟁 대응 등 회사와의 제반
                      커뮤니케이션, 회사 제공 정보에 대한 피드백 확인 및
                      제품∙서비스에 대한 불만처리기록 보관 등 회원 분석, 데이터
                      분석, 통계 및 제품 서비스 개선 활동, 관련 증빙, 기타 위
                      목적에 부대되는 회사의 업무<br />
                      ◦ 회사의 의무 이행 및 관련 법령과 내부 규정 준수
                    </td>
                  </tr>
                  <tr>
                    <td class="table_td_blue text-center" width="20%">
                      <b>보유 및 이용기간</b>
                    </td>
                    <td width="80%">
                      관계 법령의 규정에 따라 귀하의 개인정보를 보존할 의무가
                      있는 경우가 아닌 한, 회사는
                      <u class="highlight">회원 탈퇴 시까지</u> 귀하의
                      개인정보를 보유 및 이용합니다.
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      귀하는 위와 같은 개인정보의 수집 및 이용을 거부할 수
                      있습니다. 다만, 이에 동의하지 않을 경우 보건의료전문가
                      인증 및 본 웹사이트의 일부 기능 이용이 불가능할 수
                      있습니다.
                    </td>
                  </tr>
                </table>
                <v-radio-group
                  v-model="member.processPersonalInfoUse"
                  @change="uncheckall($event)"
                >
                  <v-radio
                    color="black"
                    :label="$t('register.agreeProcessPersonalInfoUse')"
                    :value="true"
                  >
                  </v-radio>
                  <v-radio
                    color="black"
                    :label="$t('register.disagree')"
                    :value="false"
                  >
                  </v-radio>
                </v-radio-group>
              </div>
              <div class="modal-footer pa-10">
                <v-btn
                  class="modal-footer-btn"
                  color="primary"
                  rounded
                  :disabled="!member.processPersonalInfoUse"
                  @click="viewProcesstoPersonalInformationUseDialog = false"
                >
                  확인
                </v-btn>
              </div>
            </div>
          </div>
        </v-dialog>
        <v-dialog
          content-class="vdialog_terms"
          v-model="viewProcesstoPersonalInformationThirdDialog"
          style="width: 50% !important"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="text-end">
                <v-btn
                  class="text-end"
                  style="margin-left: auto"
                  icon
                  @click="viewProcesstoPersonalInformationThirdDialog = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
              <div
                class="modal-header login_dialog_modal_header_second text-center"
              >
                <h5 class="modal-title" id="staticBackdropLabel">
                  2. 개인정보의 제3자 제공에 대한 동의
                </h5>
              </div>
              <div class="modal-body p-20">
                <p>
                  길리어드 사이언스 코리아 유한회사(이하 “회사”)는 아래와 같이
                  귀하의 개인정보를 처리(수집, 이용, 제공 등)합니다. 아래 사항을
                  충분히 읽어보시고, 각 사항에 동의하여 주시기 바랍니다.
                </p>
                <br />
                <!-- <h6>2. 개인정보의 제3자 제공에 대한 동의</h6> -->
                <table>
                  <tr>
                    <td colspan="5">필수적 제공</td>
                  </tr>
                  <tr class="table_td_blue text-center">
                    <td><b> 제공받는 자 (연락처)</b></td>
                    <td><b>제공받는 자가 소재하는 국가</b></td>
                    <td><b>제공받는 자의 개인정보 이용목적 </b></td>
                    <td><b>제공하는 개인정보의 항목</b></td>
                    <td>
                      <b> 제공받는 자의 개인정보 보유 및 이용기간</b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <u class="highlight"
                        >Gilead Sciences Inc. 및 계열회사1 (“길리어드 그룹 사”)
                      </u>
                    </td>
                    <td>
                      Gilead Sciences Inc.: 미국 계열회사: 아일랜드, 한국 외
                      40여개 국
                    </td>
                    <td>
                      <u class="highlight"
                        >귀하가 위 1항을 통해 수집 및 이용에 동의한 목적 일체</u
                      >
                    </td>
                    <td>
                      귀하가 위 1항을 통해 수집 및 이용에 동의한 개인정보 일체
                    </td>
                    <td>
                      <u class="highlight"
                        >관계법령 규정에 따라 개인정보를 보존하여야 하는 경우가
                        아닌 한, 탈퇴 시까지</u
                      >
                    </td>
                  </tr>
                  <tr>
                    <td colspan="5">
                      <p>
                        (길리어드 그룹사 현황은
                        <a
                          href="http://www.gilead.com/about/worldwide-operations"
                          >http://www.gilead.com/about/worldwide-operations</a
                        >에서도 확인하실 수 있습니다. Global Operations에서 각
                        지역으로 들어가면 해당 나라에 대한 관련 계열사의
                        구체적인 주소 및 연락처를 보실 수 있습니다.)
                      </p>

                      귀하는 위와 같은 개인정보의 제3자 제공을 거부할 수
                      있습니다. 다만, 이에 동의하지 않을 경우 보건의료전문가
                      인증 및 본 웹사이트의 일부 기능 이용이 불가능할 수
                      있습니다.
                    </td>
                  </tr>
                </table>
                <v-radio-group
                  v-model="member.processPersonalInfoThird"
                  @change="uncheckall($event)"
                >
                  <v-radio
                    color="black"
                    :label="$t('register.agreeProcessPersonalInfoThird')"
                    :value="true"
                  >
                  </v-radio>
                  <v-radio
                    color="black"
                    :label="$t('register.disagree')"
                    :value="false"
                  >
                  </v-radio>
                </v-radio-group>
              </div>
              <div class="modal-footer pa-10">
                <v-btn
                  class="modal-footer-btn"
                  color="primary"
                  rounded
                  :disabled="!member.processPersonalInfoThird"
                  @click="viewProcesstoPersonalInformationThirdDialog = false"
                >
                  확인
                </v-btn>
              </div>
            </div>
          </div>
        </v-dialog>

        <v-dialog
          content-class="vdialog_terms"
          v-model="viewProcesstoPersonalInformationOverseasDialog"
          style="width: 50% !important"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="text-end">
                <v-btn
                  class="text-end"
                  style="margin-left: auto"
                  icon
                  @click="
                    viewProcesstoPersonalInformationOverseasDialog = false
                  "
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
              <div
                class="modal-header login_dialog_modal_header_second text-center"
              >
                <h5 class="modal-title" id="staticBackdropLabel">
                  3. 개인정보의 국외 이전에 대한 동의
                </h5>
              </div>
              <div class="modal-body p-20">
                <p>
                  길리어드 사이언스 코리아 유한회사(이하 “회사”)는 아래와 같이
                  귀하의 개인정보를 처리(수집, 이용, 제공 등)합니다. 아래 사항을
                  충분히 읽어보시고, 각 사항에 동의하여 주시기 바랍니다.
                </p>
                <br />

                <!-- <h6>3. 개인정보의 국외 이전에 대한 동의</h6> -->

                <table>
                  <tr class="table_td_blue text-center">
                    <td>
                      <b>이전받는 자 (연락처)</b>
                    </td>
                    <td><b>이전받는 자가 소재하는 국가</b></td>
                    <td><b>이전받는 자의 개인정보 이용목적</b></td>
                    <td><b>이전하는 개인정보의 항목 </b></td>
                    <td><b> 이전일시 /방법</b></td>
                    <td>
                      <b>이전받는 자의 개인정보 보유 및 이용기간 </b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <u class="highlight">
                        Gilead Sciences Inc. 및 계열회사 (“길리어드 그 룹사”)
                      </u>
                    </td>
                    <td>
                      Gilead Sciences Inc.: 미국 계열회사: 아일랜드, 한국 외
                      40여개 국
                    </td>
                    <td>
                      <u class="highlight"
                        >귀하가 위 1항을 통해 수집 및 이용에 동의한 목적 일체
                      </u>
                    </td>
                    <td>
                      귀하가 위 1항을 통해 수집 및 이용에 동의한 개인정보 일체
                    </td>
                    <td>정보통신망을 통해 필요한 경우 수시로 이전</td>
                    <td>
                      <u class="highlight"
                        >관계법령 규정에 따라 개인정보를 보존하여야 하는 경우가
                        아닌 한, 탈퇴 시까지</u
                      >
                    </td>
                  </tr>
                  <tr>
                    <td colspan="6">
                      귀하는 위와 같은 개인정보의 국외 이전을 거부할 수
                      있습니다. 다만, 이에 동의하지 않을 경우 보건의료전문가
                      인증 및 본 웹사이트 이용이 불가능할 수 있습니다.
                    </td>
                  </tr>
                </table>

                <br /><br />
                <v-radio-group
                  v-model="member.processPersonalInfoOverseas"
                  @change="uncheckall($event)"
                >
                  <v-radio
                    color="black"
                    :label="$t('register.agreeProcessPersonalInfoOverseas')"
                    :value="true"
                  >
                  </v-radio>
                  <v-radio
                    color="black"
                    :label="$t('register.disagree')"
                    :value="false"
                  >
                  </v-radio>
                </v-radio-group>
              </div>
              <div class="modal-footer pa-10">
                <v-btn
                  class="modal-footer-btn"
                  color="primary"
                  rounded
                  :disabled="!member.processPersonalInfoOverseas"
                  @click="
                    viewProcesstoPersonalInformationOverseasDialog = false
                  "
                >
                  확인
                </v-btn>
              </div>
            </div>
          </div>
        </v-dialog>
        <v-snackbar
          v-model="snackbar.visible"
          :color="snackbar.color"
          :multi-line="snackbar.mode === 'multi-line'"
          :timeout="snackbar.timeout"
          :top="snackbar.position === 'top'"
          rounded="pill"
          width="877.27px"
        >
          <v-layout align-center pr-4>
            <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
            <v-layout column>
              <div>{{ $t(snackbar.text) }}</div>
            </v-layout>
          </v-layout>
          <v-btn
            v-if="snackbar.timeout === 0"
            icon
            @click="snackbar.visible = false"
          >
            <v-icon>clear</v-icon>
          </v-btn>
        </v-snackbar>
        <div class="mt-160 text-center">
          <span class="su1_info_title">{{ $t("register.signup") }}</span>
        </div>
        <div class="mt-40">
          <div id="app">
            <v-app id="inspire">
              <div class="row main_row_su1_info">
                <div class="col-xl-6 col-md-8 col-xs-6 col-sm-10">
                  <!-- <v-container
                    class="main_checkbox_su1_class main_row_su1_info_first"
                    fluid
                  >
                    <v-radio-group
                      v-model="member.fullyAgreeRequirements"
                      @change="checkAll($event)"
                    >
                      <v-radio
                        color="black"
                        :label="$t('register.fullyAgreeRequirements')"
                        :value="true"
                      >
                      </v-radio>
                      <v-radio
                        color="black"
                        :label="$t('register.fullyDisagreeRequirements')"
                        :value="false"
                      >
                      </v-radio>
                    </v-radio-group>
                  </v-container> -->
                  <hr class="hr_line_su1" />
                  <br />
                  <v-container
                    class="main_checkbox_su1_class main_row_su1_info_second"
                    fluid
                    @click="viewTermsandConditionsdialog = true"
                  >
                    <v-checkbox
                      color="black"
                      readonly
                      v-model="member.termsAndConditions"
                      @change="uncheckall($event)"
                      :append-icon="termsViewIcon"
                      @click:append="viewTermsandConditionsdialog = true"
                    >
                      <template v-slot:label>
                        {{ $t("register.termsAndConditions") }}
                        <a class="btn-show-detail"> 확인 및 동의 </a>
                      </template>
                    </v-checkbox>
                    <!-- <v-btn @click="viewTermsandConditionsdialog = true">
                    <span class="mdi mdi-chevron-right"></span></v-btn> -->
                    <!-- <p class="su1_info_sub_warning mt-2 ms-4">선택항목에 대한 동의를 거부하시는 경우에도 서비스는 이용이 가능합니다.</p> -->
                  </v-container>

                  <!-- Removed coz QC Suggestion
                  <v-container class="main_checkbox_su1_class main_row_su1_info_second" fluid>
                    <v-checkbox color="black" v-model="member.useofPersonalInfo" @change="uncheckall($event)"
                      :label="$t('register.useofPersonalInfo')">
                    </v-checkbox>
                  </v-container> -->

                  <v-container
                    class="main_checkbox_su1_class main_row_su1_info_second"
                    fluid
                    @click="viewProcesstoPersonalInformationUseDialog = true"
                  >
                    <v-checkbox
                      color="black"
                      readonly
                      v-model="member.processPersonalInfoUse"
                      @change="uncheckall($event)"
                      :append-icon="termsViewIcon"
                      @click:append="
                        viewProcesstoPersonalInformationUseDialog = true
                      "
                    >
                      <template v-slot:label>
                        {{ $t("register.processPersonalInfoUse") }}
                        <a class="btn-show-detail"> 확인 및 동의 </a>
                      </template>
                    </v-checkbox>
                    <!-- <v-btn @click="viewProcesstoPersonalInformationdialog = true">
                    <span class="mdi mdi-chevron-right"></span></v-btn> -->
                  </v-container>

                  <v-container
                    class="main_checkbox_su1_class main_row_su1_info_second"
                    fluid
                    @click="viewProcesstoPersonalInformationThirdDialog = true"
                  >
                    <v-checkbox
                      color="black"
                      readonly
                      v-model="member.processPersonalInfoThird"
                      @change="uncheckall($event)"
                      :append-icon="termsViewIcon"
                      @click:append="
                        viewProcesstoPersonalInformationThirdDialog = true
                      "
                    >
                      <template v-slot:label>
                        {{ $t("register.processPersonalInfoThird") }}
                        <a class="btn-show-detail"> 확인 및 동의 </a>
                      </template>
                    </v-checkbox>
                  </v-container>
                  <v-container
                    class="main_checkbox_su1_class main_row_su1_info_second"
                    fluid
                    @click="
                      viewProcesstoPersonalInformationOverseasDialog = true
                    "
                  >
                    <v-checkbox
                      color="black"
                      readonly
                      v-model="member.processPersonalInfoOverseas"
                      @change="uncheckall($event)"
                      :append-icon="termsViewIcon"
                      @click:append="
                        viewProcesstoPersonalInformationOverseasDialog = true
                      "
                    >
                      <template v-slot:label>
                        {{ $t("register.processPersonalInfoOverseas") }}
                        <a class="btn-show-detail"> 확인 및 동의 </a>
                      </template>
                    </v-checkbox>
                  </v-container>

                  <v-row class="login_form_row mt-50 button-row">
                    <v-col>
                      <v-btn
                        class="login_button_down"
                        @click="submit"
                        rounded
                        >{{ $t("register.next") }}</v-btn
                      >
                    </v-col>
                    <v-col>
                      <v-btn
                        class="login_button_down_button"
                        @click="cancelTerms"
                        rounded
                      >
                        {{ $t("register.cancel") }}</v-btn
                      >
                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-app>
          </div>
        </div>

        <!-- <div class="su1_info_main_form_btn mt-90">
          <v-btn class="btn su1_info_form_btn" type="button" @click="submit">{{ $t("register.next") }}</v-btn>
          <v-btn class="btn su1_info_form_btn2" type="button" @click="cancelTerms">{{ $t("register.cancel") }}</v-btn>
        </div> -->
        <!-- Removed coz of Suggestion
        <footer class="main_footer mt-70 bg-light text-center text-lg-start">
          <div class="text-start p-2">
            <img class="footer_logo_main ms-3" src="@/assets/images/footer_logo.png" alt="">
          </div>
        </footer> -->
      </v-form>
    </div>
    <div v-show="!toggle">
      <div v-if="submitted === 'register'">
        <div class="container login_main_body">
          <div class="row justify-content-center align-items-center">
            <div
              class="admin_main_row col-xl-4 col-lg-6 col-md-8 col-sm-10 col-xs-12"
            >
              <div class="row text-center">
                <div class="col-md-12">
                  <h3 class="signup_title">{{ $t("register.signup") }}</h3>
                </div>
              </div>
              <div class="row form-wrap">
                <div class="col-md-12">
                  <v-form ref="form" v-model="validRegister" lazy-validation>
                    <v-snackbar
                      v-model="snackbar.visible"
                      :color="snackbar.color"
                      :multi-line="snackbar.mode === 'multi-line'"
                      rounded="pill"
                      width="877.27px"
                      :timeout="snackbar.timeout"
                      :top="snackbar.position === 'top'"
                    >
                      <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{
                          snackbar.icon
                        }}</v-icon>
                        <v-layout column>
                          <div>{{ $t(snackbar.text) }}</div>
                        </v-layout>
                      </v-layout>
                      <v-btn
                        v-if="snackbar.timeout === 0"
                        icon
                        @click="snackbar.visible = false"
                      >
                        <v-icon>clear</v-icon>
                      </v-btn>
                    </v-snackbar>
                    <v-container>
                      <v-row class="login_form_row">
                        <v-col>
                          <!-- Removed coz Suggestion by QC -->
                          <!-- <v-text-field class="login_textfield_label" v-model.trim="user.name" :rules="nameRules"
                            :label="$t('register.name')" :placeholder="$t('register.nameplaceholder')" filled rounded
                            dense required>
                          </v-text-field> -->

                          <v-text-field
                            class="login_textfield_label"
                            v-model.trim="user.name"
                            :label="$t('register.name')"
                            :placeholder="$t('register.nameplaceholder')"
                            filled
                            rounded
                            dense
                            required
                          >
                          </v-text-field>
                          <span
                            class="signup_rules_msg signup_rules_msg_register"
                            >{{ $t("register.nameValidationnote") }}</span
                          >
                        </v-col>
                      </v-row>
                      <v-row class="login_form_row">
                        <v-col>
                          <v-text-field
                            class="login_textfield_label"
                            v-model="user.email"
                            :rules="emailRules"
                            :label="$t('register.email')"
                            :placeholder="$t('register.emailplaceholder')"
                            required
                            filled
                            rounded
                            dense
                          >
                          </v-text-field>
                          <!-- <span class="ms-3 span_validation_msg">*아이디는 이메일 형식이여야
                                                                합니다.</span> -->
                        </v-col>
                      </v-row>
                      <v-row class="login_form_row">
                        <v-col>
                          <!-- Removed coz QC Suggestion
                          <v-text-field class="login_textfield_label" v-model="user.password" :rules="passwordRules"
                            name="user.password" :label="$t('register.password')" type="password"
                            :placeholder="$t('register.password')" @keydown.enter="passwordValidationWithIcon"
                            :append-icon='copyIcon' required filled rounded dense>
                          </v-text-field> -->
                          <v-text-field
                            class="login_textfield_label"
                            v-model="user.password"
                            name="user.password"
                            :label="$t('register.password')"
                            type="password"
                            :placeholder="$t('register.passwordplaceholder')"
                            @keyup="passwordValidationWithIcon"
                            :append-icon="copyIcon"
                            :color="passwordValidationiconColor"
                            required
                            filled
                            rounded
                            dense
                          >
                          </v-text-field>
                          <span class="signup_rules_msg">{{
                            $t("register.passwordValidationnote")
                          }}</span>
                        </v-col>
                      </v-row>
                      <v-row class="login_form_row">
                        <v-col>
                          <v-text-field
                            class="login_textfield_label"
                            v-model="user.confirmPassword"
                            name="user.confirmPassword"
                            :label="$t('register.confirmPassword')"
                            type="password"
                            :placeholder="
                              $t('register.confirmPasswordplaceholder')
                            "
                            :rules="confirmpasswordRules"
                            required
                            filled
                            rounded
                            dense
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row
                        class="login_form_row login_form_row_year_date_month nowrap-overflow"
                      >
                        <v-text-field
                          v-model.number="year"
                          class="dateTimeInputs"
                          :rules="yearRules"
                          type="number"
                          :label="$t('register.year')"
                          :placeholder="$t('register.yearplaceholder')"
                          @keypress="isNumber($event)"
                          required
                          filled
                          rounded
                          dense
                        >
                        </v-text-field>
                        <v-select
                          class="vselect_month_year_date"
                          v-model="month"
                          :items="months"
                          :label="$t('register.month')"
                          :placeholder="$t('register.monthplaceholder')"
                          required
                          filled
                          rounded
                          dense
                        >
                        </v-select>
                        <v-text-field
                          v-model.number="day"
                          class="dateTimeInputs input-date"
                          :rules="dayRules"
                          type="number"
                          :label="$t('register.day')"
                          :placeholder="$t('register.dayplaceholder')"
                          @keypress="isNumber($event)"
                          required
                          filled
                          rounded
                          dense
                        >
                        </v-text-field>
                        <!-- <v-col class="signup_select_date_class">
                          <v-text-field v-model.number="year"
                            :rules="[v => !v || /^[0-9]{0,4}$/.test(v) || '4 digits only']" type="number"
                            label="Year (4 digits)" required filled rounded dense>
                          </v-text-field>
                        </v-col> -->
                      </v-row>

                      <v-row class="login_form_row">
                        <v-col class="signup_select_class">
                          <v-select
                            v-model="user.licenseType"
                            :items="licenseTypes"
                            :label="$t('register.licenseType')"
                            :placeholder="$t('register.licenseTypeplaceholder')"
                            required
                            filled
                            rounded
                            dense
                          >
                          </v-select>
                        </v-col>
                      </v-row>
                      <v-row class="login_form_row">
                        <v-col>
                          <!-- Removed coz QC Suggestion -->
                          <!-- <v-text-field class="login_textfield_label" v-model="user.licenseNumber"
                            :rules="licenseNumberRules" @keyup.enter="saveMember" :label="$t('register.licenseNumber')"
                            :placeholder="$t('register.licenseNumberplaceholder')" required filled rounded dense>
                          </v-text-field> -->

                          <v-text-field
                            class="login_textfield_label"
                            v-model="user.licenseNumber"
                            @keyup.enter="saveMember"
                            :label="$t('register.licenseNumber')"
                            :placeholder="
                              $t('register.licenseNumberplaceholder')
                            "
                            required
                            filled
                            rounded
                            dense
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="login_form_row">
                        <v-col>
                          <p class="signup_rules_msg">
                            {{ $t("register.signupRulesMsg") }}
                          </p>
                        </v-col>
                      </v-row>
                      <v-row class="login_form_row mt-50 button-row">
                        <v-col>
                          <v-btn
                            class="login_button_down"
                            @click="saveMember"
                            rounded
                            >{{ $t("register.ok") }}</v-btn
                          >
                        </v-col>
                        <v-col>
                          <v-btn
                            class="login_button_down_button"
                            @click="cancelMember"
                            rounded
                          >
                            {{ $t("register.cancel") }}</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Removed coz of Suggestion
        <footer class="mt-80 main_footer bg-light text-center text-lg-start">
          <div class="text-start p-2">
            <img class="footer_logo_main ms-3" src="@/assets/images/footer_logo.png" alt="">
          </div>
        </footer> -->
      </div>
      <div v-else-if="submitted === 'showRegister'">
        <div class="main">
          <div class="mt-160">
            <p class="su3_info_title text-center">
              {{ $t("register.membershipRegistrationInformation") }}
            </p>
          </div>
          <div class="mt-70">
            <p class="su3_info_sub_title text-center">
              {{ $t("register.checkInformation") }}
            </p>
          </div>
          <section class="su3_info_section mt-30">
            <div class="su3_info_main align-self-center">
              <ul
                class="su3_info_main_ul_class_details align-self-center ms-3 p-4"
              >
                <li>
                  {{ $t("register.nameforShowRegister") }} :<b>
                    {{ this.user.name }}</b
                  >
                </li>
                <li>
                  {{ $t("register.emailforShowRegister") }} :<b>
                    {{ this.user.email }}</b
                  >
                </li>
                <li>
                  {{ $t("register.licenseNumberoptional") }}:
                  <b> {{ this.user.licenseNumber }}</b>
                </li>
                <li>
                  {{ $t("register.dateofBirth") }} :<b>
                    {{ this.year }}.{{ this.month }}.{{ this.day }}</b
                  >
                </li>
              </ul>
            </div>
          </section>
          <div class="su3_info_main_form_btn mt-60 button-row">
            <v-btn
              class="btn su3_info_form_btn"
              type="button"
              rounded
              dark
              @click="Register"
              >{{ $t("register.ok") }}</v-btn
            >
            <v-btn
              class="btn su3_info_form_btn2 mt-5"
              type="button"
              rounded
              dark
              @click="cancelRegister"
              >{{ $t("register.cancel") }}
            </v-btn>
          </div>
          <!-- Removed coz of Suggestion
          <footer class="main_footer mt-70 bg-light text-center text-lg-start">
            <div class="text-start p-2">
              <img class="footer_logo_main ms-3" src="@/assets/images/footer_logo.png" alt="">
            </div>
          </footer> -->
        </div>
      </div>
      <div v-else-if="submitted === 'confirmRegister'">
        <div class="main container">
          <div class="mt-280">
            <p class="su4_info_title text-center">
              {{ $t("register.completionNotice") }}
            </p>
          </div>
          <div class="mt-70">
            <p class="su4_info_sub_title text-center">
              {{ $t("register.completionNoticeMessage") }}
            </p>
          </div>
          <div class="su4_info_main_form_btn mt-90">
            <v-btn
              class="btn su4_info_form_btn"
              type="button"
              rounded
              @click="goLogin"
              >{{ $t("register.ok") }}</v-btn
            >
          </div>
          <!-- Removed coz of Suggestion
          <footer class="main_footer mt-100 bg-light text-center text-lg-start">
            <div class="text-start p-2">
              <img class="footer_logo_main ms-3" src="@/assets/images/footer_logo.png" alt="">
            </div>
          </footer> -->
        </div>
      </div>
    </div>
  </div>
</template>
    
<script>
// import '@/assets/css/bootstrap.min.css'
import "@/assets/css/style.css";
import "@/assets/jquery/jquery.min.js";
// import '@/assets/js/bootstrap.bundle.js'
import "@/assets/js/vue.js";
// import '@/assets/js/vuetify.js'
import "@/assets/css/vuetify.min.css";
import MemberDataService from "@/services/MemberDataService";

export default {
  name: "add-user",
  data() {
    return {
      user: {
        id: null,
        name: "",
        email: "",
        licenseType: null,
        password: "",
        confirmPassword: "",
        birth: "",
        licenseNumber: "",
        fullyAgreeRequirements: 0,
        termsAndConditions: 0,
        useofPersonalInfo: 0,
        processPersonalInfo: 0,
        processPersonalInfoUse: 0,
        processPersonalInfoThird: 0,
        processPersonalInfoOverseas: 0,
        deliveringMedicalAndPharmaceuticalInfo: 0,
      },
      member: {
        fullyAgreeRequirements: 0,
        termsAndConditions: 0,
        useofPersonalInfo: 0,
        processPersonalInfo: 0,
        processPersonalInfoUse: 0,
        processPersonalInfoThird: 0,
        processPersonalInfoOverseas: 0,
        deliveringMedicalAndPharmaceuticalInfo: 0,
      },
      whenDisagreeDialog: false,
      viewTermsandConditionsdialog: false,
      viewProcesstoPersonalInformationdialog: false,
      viewProcesstoPersonalInformationUseDialog: false,
      viewProcesstoPersonalInformationThirdDialog: false,
      viewProcesstoPersonalInformationOverseasDialog: false,
      copyIcon: "",
      termsViewIcon: "mdi mdi-chevron-right",
      toggle: true,
      submitted: "register",
      valid: true,
      day: "",
      month: null,
      year: "",
      months: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ],
      licenseTypes: [
        {
          text: this.$t("myInfo.licenseTypes.Doctor"),
          value: "Doctor",
        },
        {
          text: this.$t("myInfo.licenseTypes.Nurse"),
          value: "Nurse",
        },
        {
          text: this.$t("myInfo.licenseTypes.Pharmacist"),
          value: "Pharmacist",
        },
      ],
      //Terms
      buttons: [
        {
          color: "warning",
          title: "Warning",
          type: "warning",
        },
      ],
      snackbar: {
        color: null,
        icon: null,
        mode: null,
        position: "top",
        text: null,
        timeout: 2500,
        title: null,
        visible: false,
      },
      randomKey: "",
      validRegister: true,
      passwordValidationiconColor: "",
    };
  },
  methods: {
    checkAll(e) {
      if (e === true) {
        console.log("check All");
        this.member.termsAndConditions = true;
        this.member.processPersonalInfoUse = true;
        this.member.processPersonalInfoThird = true;
        this.member.processPersonalInfoOverseas = true;
      } else {
        this.whenDisagreeDialog = true;
        this.member.termsAndConditions = false;
        this.member.processPersonalInfoUse = false;
        this.member.processPersonalInfoThird = false;
        this.member.processPersonalInfoOverseas = false;
      }
    },
    uncheckall(e) {
      if (e === false) {
        this.whenDisagreeDialog = true;
        this.member.fullyAgreeRequirements = false;
      }
      if (
        this.member.termsAndConditions == true &&
        this.member.processPersonalInfoUse == true &&
        this.member.processPersonalInfoThird == true &&
        this.member.processPersonalInfoOverseas == true
      ) {
        this.member.fullyAgreeRequirements = true;
      }
    },
    getMember(id) {
      console.log("Id is : " + id);
      // MemberDataService.get(id)
      //   .then((response) => {
      //     this.user.id = response.data.id;
      //     console.log(response.data);
      //   })
      //   .catch((e) => {
      //     console.log(e);
      //   });
    },
    validate() {
      this.$refs.form.validate();
    },
    cancelTerms() {
      // this.$router.push({ name: "login" });
      this.$router.push("login");
      this.user = {};
    },
    saveMember() {
      this.$refs.form.validate();
      var passwordValidateRegex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,50}$/;
      if (this.user.name == "") {
        this.SnackbarShow("name");
        return false;
      }
      if (this.user.name.length > 5) {
        this.SnackbarShow("nameRulesValidation");
        return false;
      }
      if (this.user.email == "") {
        this.SnackbarShow("email");
        return false;
      }
      if (this.user.password == "") {
        this.SnackbarShow("password");
        return false;
      }
      if (this.user.password != "") {
        if (!this.user.password.match(passwordValidateRegex)) {
          this.SnackbarShow("passwordvalidationMessage");
          return false;
        }
      }
      if (this.user.confirmPassword == "") {
        this.SnackbarShow("confirmPassword");
        return false;
      }
      if (this.year == "") {
        this.SnackbarShow("year");
        return false;
      }
      // if (this.year > (new Date()).getFullYear()) {
      //   this.SnackbarShow("validateDate");
      //   return false;
      // }
      if (this.month == "" || this.month == null) {
        this.SnackbarShow("month");
        return false;
      }
      // if (this.month > (new Date()).getMonth() + 1) {
      //   this.SnackbarShow("validateDate");
      //   return false;
      // }
      if (this.day == "") {
        this.SnackbarShow("day");
        return false;
      }
      if (!this.validateDay()) {
        this.SnackbarShow("daymustbenumberofmonthValidate");
        return false;
      }
      // if (this.day > (new Date()).getDate()) {
      //   this.SnackbarShow("validateDate");
      //   return false;
      // }

      var q = new Date();
      var m = q.getMonth() + 1;
      var d = q.getDate();
      var y = q.getFullYear();
      var currentDateString = y + "-" + m + "-" + d;
      var currentDate = new Date(currentDateString);
      var tempDateString = this.year + "-" + this.month + "-" + this.day;
      var tempDate = new Date(tempDateString);

      console.log(currentDate + " " + currentDateString + " " + q.getDate());
      console.log(tempDate + " " + tempDateString);

      if (tempDate > currentDate) {
        this.SnackbarShow("validateDate");
        return false;
      }

      if (this.user.licenseType == "" || this.user.licenseType == null) {
        this.SnackbarShow("licenseType");
        return false;
      }
      if (this.user.licenseNumber == "") {
        this.SnackbarShow("licenseNumber");
        return false;
      }
      if (this.user.licenseNumber.length > 20) {
        this.SnackbarShow("licensenumberRulesValidationMessage");
        return false;
      }
      //       if(!this.user.password.match(passwordValidateRegex)) {
      //   this.SnackbarShow("password");
      //   return false;
      // }
      var data = {
        username: this.user.email,
        licenseNumber: this.user.licenseNumber,
      };
      // data.key=keyCheck(data.key);
      MemberDataService.emailLicenceCheck(data)
        .then((response) => {
          if (response.data != true) {
            switch (response.data) {
              case "Email Already Exists":
                this.SnackbarShow("emailAlreadyExists");
                return false;
                break;
              case "LicenseNumber Already Exists":
                this.SnackbarShow("licenseNumberAlreadyExists");
                return false;
                break;
            }
          } else {
            if (this.$refs.form.validate()) {
              this.submitted = "showRegister";
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    //To generate 6 digit key
    keyCheck(key) {
      this.randomKey = key;
      MemberDataService.keyCheck(key)
        .then((response) => {
          if (response.data != true) {
            this.randomKey = Math.floor(100000 + Math.random() * 900000);
            this.keyCheck(this.randomKey);
          } else {
            return this.randomKey;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    cancelMember() {
      // this.$router.push({ name: "login" });
      this.$router.push("login");
      this.user = {};
    },
    cancelRegister() {
      this.submitted = "register";
      //this.user = {};
    },
    Register() {
      var data = {
        name: this.user.name,
        email: this.user.email,
        // password: this.user.password.split("").reverse().join(""),
        password: this.user.password,
        birth: this.year + "-" + this.month + "-" + this.day,
        hcpApproval: "Waiting for approval",
        roles: "member",
        licenseType: this.user.licenseType,
        licenseNumber: this.user.licenseNumber,
        isFullyAgree: this.user.fullyAgreeRequirements,
        isTermsandConditions: this.user.termsAndConditions,
        isUseofPersonalInfo: this.user.useofPersonalInfo,
        isProcessPersonalInfo: this.user.processPersonalInfo,
        processPersonalInfoUse: this.user.processPersonalInfoUse,
        processPersonalInfoThird: this.user.processPersonalInfoThird,
        processPersonalInfoOverseas: this.user.processPersonalInfoOverseas,
        isDeliveringMedicalAndPharmaceuticalInfo:
          this.user.deliveringMedicalAndPharmaceuticalInfo,
      };
      console.log(data);
      // return;
      MemberDataService.register(data)
        .then((response) => {
          this.user.id = response.data.id;
          // console.log(response.data);
          this.submitted = "confirmRegister";
        })
        .catch((e) => {
          console.log(e);
        });
    },
    submit() {
      if (
        this.member.termsAndConditions == 0 ||
        this.member.processPersonalInfoUse == 0 ||
        this.member.processPersonalInfoThird == 0 ||
        this.member.processPersonalInfoOverseas == 0
      ) {
        if (this.member.termsAndConditions == 0) {
          this.SnackbarShow("commonTerm");
          return false;
        }
        // else if (this.member.useofPersonalInfo == 0) {
        //   this.SnackbarShow("commonTerm");
        //   return false;
        // }
        else if (this.member.processPersonalInfoUse == 0) {
          this.SnackbarShow("commonTerm");
          return false;
        } else if (this.member.processPersonalInfoThird == 0) {
          this.SnackbarShow("commonTerm");
          return false;
        } else if (this.member.processPersonalInfoOverseas == 0) {
          this.SnackbarShow("commonTerm");
          return false;
        }
      } else {
        this.user.fullyAgreeRequirements = this.member.fullyAgreeRequirements;
        this.user.termsAndConditions = this.member.termsAndConditions;
        // this.user.useofPersonalInfo = this.member.useofPersonalInfo;
        this.user.processPersonalInfoUse = this.member.processPersonalInfoUse;
        this.user.processPersonalInfoThird =
          this.member.processPersonalInfoThird;
        this.user.processPersonalInfoOverseas =
          this.member.processPersonalInfoOverseas;
        this.user.deliveringMedicalAndPharmaceuticalInfo =
          this.member.deliveringMedicalAndPharmaceuticalInfo;
        this.member = "";
        this.toggle = !this.toggle;
        // MemberDataService.register(data)
        //   .then((response) => {
        //     console.log(response.data);
        //   })
        //   .catch((e) => {
        //     console.log(e);
        //   });
      }
      //   this.$refs.form.validate()
    },
    goLogin() {
      // this.$router.push({ name: "login" });
      this.$router.push("login");
      // router.push("login")
    },
    SnackbarShow(type) {
      switch (type) {
        case "term1":
          this.snackbar = {
            color: "#FF5252",
            icon: "mdi-alert-circle-outline",
            mode: "multi-line",
            position: "top",
            timeout: 2000,
            title: "Warning",
            text: "register.termsAndConditionsValidation",
            visible: true,
          };
          break;
        case "term2":
          this.snackbar = {
            color: "#FF5252",
            icon: "mdi-alert-circle-outline",
            mode: "multi-line",
            position: "top",
            timeout: 2000,
            title: "Warning",
            text: "register.useofPersonalInfoValidation",
            visible: true,
          };
          break;
        case "term3":
          this.snackbar = {
            color: "#FF5252",
            icon: "mdi-alert-circle-outline",
            mode: "multi-line",
            position: "top",
            timeout: 2000,
            title: "Warning",
            text: "register.processPersonalInfoValidation",
            visible: true,
          };
          break;
        case "commonTerm":
          this.snackbar = {
            color: "#FF5252",
            icon: "mdi-alert-circle-outline",
            mode: "multi-line",
            position: "top",
            timeout: 2000,
            title: "Warning",
            text: "register.commonTermValidation",
            visible: true,
          };
          break;
        case "name":
          this.snackbar = {
            color: "#FF5252",
            icon: "mdi-alert-circle-outline",
            mode: "multi-line",
            position: "top",
            timeout: 2000,
            title: "Warning",
            text: "register.nameValidation",
            visible: true,
          };
          break;
        case "nameRulesValidation":
          this.snackbar = {
            color: "#FF5252",
            icon: "mdi-alert-circle-outline",
            mode: "multi-line",
            position: "top",
            timeout: 2000,
            title: "Warning",
            text: "register.nameRulesValidation",
            visible: true,
          };
          break;
        case "email":
          this.snackbar = {
            color: "#FF5252",
            icon: "mdi-alert-circle-outline",
            mode: "multi-line",
            position: "top",
            timeout: 2000,
            title: "Warning",
            text: "register.emailValidation",
            visible: true,
          };
          break;
        case "password":
          this.snackbar = {
            color: "#FF5252",
            icon: "mdi-alert-circle-outline",
            mode: "multi-line",
            position: "top",
            timeout: 2000,
            title: "Warning",
            text: "register.passwordValidation",
            visible: true,
          };
          break;
        case "passwordvalidationMessage":
          this.snackbar = {
            color: "#FF5252",
            icon: "mdi-alert-circle-outline",
            mode: "multi-line",
            position: "top",
            timeout: 2000,
            title: "Warning",
            text: "register.passwordvalidationMessage",
            visible: true,
          };
          break;
        case "confirmPassword":
          this.snackbar = {
            color: "#FF5252",
            icon: "mdi-alert-circle-outline",
            mode: "multi-line",
            position: "top",
            timeout: 2000,
            title: "Warning",
            text: "register.confirmPasswordValidation",
            visible: true,
          };
          break;
        case "year":
          this.snackbar = {
            color: "#FF5252",
            icon: "mdi-alert-circle-outline",
            mode: "multi-line",
            position: "top",
            timeout: 2000,
            title: "Warning",
            text: "register.yearValidation",
            visible: true,
          };
          break;
        case "validateDate":
          this.snackbar = {
            color: "#FF5252",
            icon: "mdi-alert-circle-outline",
            mode: "multi-line",
            position: "top",
            timeout: 2000,
            title: "Warning",
            text: "register.validateDate",
            visible: true,
          };
          break;
        case "month":
          this.snackbar = {
            color: "#FF5252",
            icon: "mdi-alert-circle-outline",
            mode: "multi-line",
            position: "top",
            timeout: 2000,
            title: "Warning",
            text: "register.monthValidation",
            visible: true,
          };
          break;
        case "daymustbenumberofmonthValidate":
          this.snackbar = {
            color: "#FF5252",
            icon: "mdi-alert-circle-outline",
            mode: "multi-line",
            position: "top",
            timeout: 2000,
            title: "Warning",
            text: "register.daymustbenumberofmonthValidation",
            visible: true,
          };
          break;
        case "day":
          this.snackbar = {
            color: "#FF5252",
            icon: "mdi-alert-circle-outline",
            mode: "multi-line",
            position: "top",
            timeout: 2000,
            title: "Warning",
            text: "register.dayValidation",
            visible: true,
          };
          break;
        case "licenseType":
          this.snackbar = {
            color: "#FF5252",
            icon: "mdi-alert-circle-outline",
            mode: "multi-line",
            position: "top",
            timeout: 2000,
            title: "Warning",
            text: "register.licensetypeValidation",
            visible: true,
          };
          break;
        case "licenseNumber":
          this.snackbar = {
            color: "#FF5252",
            icon: "mdi-alert-circle-outline",
            mode: "multi-line",
            position: "top",
            timeout: 2000,
            title: "Warning",
            text: "register.licensenumberValidation",
            visible: true,
          };
          break;
        case "licensenumberRulesValidationMessage":
          this.snackbar = {
            color: "#FF5252",
            icon: "mdi-alert-circle-outline",
            mode: "multi-line",
            position: "top",
            timeout: 2000,
            title: "Warning",
            text: "register.licensenumberRulesValidation",
            visible: true,
          };
          break;
        case "emailAlreadyExists":
          this.snackbar = {
            color: "#FF5252",
            icon: "mdi-alert-circle-outline",
            mode: "multi-line",
            position: "top",
            timeout: 2000,
            title: "Warning",
            text: "register.emailAlreadyExists",
            visible: true,
          };
          break;
        case "licenseNumberAlreadyExists":
          this.snackbar = {
            color: "#FF5252",
            icon: "mdi-alert-circle-outline",
            mode: "multi-line",
            position: "top",
            timeout: 2000,
            title: "Warning",
            text: "register.licenseNumberAlreadyExists",
            visible: true,
          };
          break;
      }
    },
    mounted() {
      this.message = "";
      this.getMember(this.$route.params.id);
    },
    reverse(data) {
      const reverse = data.split("").reverse().join("");
      return reverse;
    },
    passwordValidationWithIcon() {
      this.copyIcon = "mdi-check-circle";
      var passwordValidateRegex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,50}$/;
      if (!this.user.password.match(passwordValidateRegex)) {
        this.passwordValidationiconColor = "#B00020";
      } else {
        this.passwordValidationiconColor = "#466FFF";
      }
    },

    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    validateDay() {
      console.log("In validate");
      if (
        this.year < 1000 ||
        this.year > 3000 ||
        this.month == 0 ||
        this.month > 12
      ) {
        return false;
      }

      var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

      // Adjust for leap years
      if (
        this.year % 400 == 0 ||
        (this.year % 100 != 0 && this.year % 4 == 0)
      ) {
        monthLength[1] = 29;
      }

      // Check the range of the day
      if (this.day > 0 && this.day <= monthLength[this.month - 1]) {
        return true;
      }
      if (this.day <= 0 && this.day > monthLength[this.month - 1]) {
        return false;
      }
    },
  },
  computed: {
    nameRules() {
      return [(v) => v.length <= 5 || this.$t("register.nameRulesMessage")];
    },
    emailRules() {
      // return [v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t('register.emailRulesMessage')];
      return [
        (v) =>
          !v ||
          // /^[-a-zA-Z0-9_.]+@[-a-zA-Z0-9]+\.[a-zA-Z]{2,4}$/.test(v) ||
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          this.$t("register.emailRulesMessage"),
      ];
    },
    passwordRules() {
      return [
        (v) =>
          !v ||
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,50}$/.test(
            v
          ) ||
          this.$t("register.passwordRulesMessage"),
      ];
    },
    confirmpasswordRules() {
      return [
        this.reverse(this.user.password) ===
          this.reverse(this.user.confirmPassword) ||
          this.$t("register.confirmpasswordRulesMessage"),
      ];
    },
    yearRules() {
      return [
        (v) =>
          !v || /^[0-9]{4}$/.test(v) || this.$t("register.yearRulesMessage"),
      ];
    },
    dayRules() {
      return [
        (v) =>
          /^[0-9]{0,2}$/.test(v) ||
          this.$t("register.dayRulesMessagefortwodigits"),
        (v) => v < 32 || this.$t("register.dayRulesMessageformustbemonth"),
      ];
    },
    licenseNumberRules() {
      return [
        (v) => v.length <= 20 || this.$t("register.licenseNumberRulesMessage"),
      ];
    },
  },
  filters: {},
};
</script>
    
<style>
.signup_rules_msg_register {
  position: relative;
  /* top: 10px; */
}
.main_row_su1_info {
  margin-bottom: 50px !important;
}

p.signup_rules_msg {
  margin-top: -22px;
}

.v-messages__message {
  line-height: 17px;
}

.v-text-field__slot {
  margin-bottom: 5px !important;
}

.submit-form {
  max-width: 800px;
}

.nowrap-overflow {
  flex-wrap: nowrap;
  overflow-x: auto;
}

.main {
  font-family: "Noto Sans" !important;
}

.v-application {
  font-family: "Noto Sans" !important;
}

.warningClass {
  color: #929a92;
  font-size: 16px;
  left: 585px;
  margin-left: 1.5rem !important;
  margin-top: 0.5rem !important;
}

/* body {
  background-color: white !important;
} */

.main_footer {
  background-color: #f8f9fa !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.mt-18 {
  margin-top: 18px !important;
}

.mt-110 {
  margin-top: 110px !important;
}

.embed_main_div {
  /* display: flex; */
}

/* For Remove up down arrow from input type number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input {
  padding-bottom: 12px !important;
}

@media only screen and (max-width: 576px) {
  div.register_signup_main_app {
    /* padding: 0px !important; */
  }
  div.register_signup_main_app .col-md-12,
  div.register_signup_main_app .col-xl-4,
  div.register_signup_main_app .col-lg-6,
  div.register_signup_main_app .col-md-8,
  div.register_signup_main_app .col-sm-10,
  div.register_signup_main_app .col-xs-12 {
    padding: 0;
  }
  .v-input--selection-controls {
    margin-top: 20px;
    padding-top: 20px;
  }
  .v-input--selection-controls .btn-show-detail {
    display: none;
  }
  .login_form_row_year_date_month .v-messages.error--text {
    width: calc(100vw - 120px);
    position: absolute;
  }
  .login_form_row_year_date_month .input-date .v-messages.error--text {
    right: 0;
    text-align: right;
  }
  .login_form_row.button-row {
    flex-direction: column;
  }
  .login_form_row.button-row .col {
    padding: 10px 0;
  }
  .login_form_row_year_date_month .v-input:not(.primary-text) .v-label {
    font-size: 12px;
  }

  .vdialog_terms.v-dialog .modal-footer .v-btn.modal-footer-btn,
  .vdialog_disagree.v-dialog .modal-footer .v-btn.modal-footer-btn {
    width: calc(50% - 8px) !important;
  }
  .modal-body table,
  th,
  td {
    font-size: 10px;
  }
  .modal-body table u.highlight {
    font-size: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .register_signup_main_app {
    padding: 40px 20px !important;
  }
  div.form-wrap {
    margin-top: 10px !important;
  }
}

@media only screen and (max-width: 768px) {
  .v-dialog--active {
    width: 80% !important;
  }
  div.vdialog_terms,
  div.vdialog_disagree {
    width: 100% !important;
  }
}

.register_signup_main_app {
  padding: 50px 20px;
}
.form-wrap {
  margin-top: 50px !important;
}

.vcard_register {
  width: 100%;
  height: auto;
  justify-content: center;
  text-align: center;
  margin: 0;
  padding: 0% !important;
}

.v_dialog_width_auto {
  /* width: auto !important; */
}

/* .v-dialog {
  width: auto !important;
} */
/* .v-dialog--active {
  width: 50%;
} */
.vdialog_terms {
  width: 70% !important;
  max-width: 1200px;
}
.vdialog_disagree {
  width: 40% !important;
}
.vdialog_terms .modal-dialog,
.vdialog_disagree .modal-dialog {
  background: #ffffff;
}

.vdialog_terms .modal-footer,
.vdialog_disagree .modal-footer {
  text-align: center;
}
.vdialog_terms .modal-footer .v-btn.modal-footer-btn,
.vdialog_disagree .modal-footer .v-btn.modal-footer-btn {
  height: 44px;
  width: 180px;
}
.vdialog_terms .v-input--radio-group__input .theme--light.v-label,
.vdialog_disagree .v-input--radio-group__input .theme--light.v-label {
  font-size: 16px;
  color: #000000;
}

.iframe_main_class {
  width: 670px;
  height: 600px;
}

.p-20 {
  padding: 20px !important;
}

table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 5px;
}
table u.highlight {
  font-size: 18px;
}
table u.red-text {
  color: var(--v-anchor-base);
}
table a {
  color: #0028ff;
  text-decoration: underline;
}
.table_td_blue {
  background-color: #adc2e6;
}

.login_form_row_year_date_month {
  margin-top: 12px !important;
  margin-bottom: -10px !important;
  align-items: start;
}

.vselect_month_year_date {
  padding: 0px 10px !important;
}
.v-input__append-outer {
  margin-top: 15px !important;
}
.register_signup_main_app .v-input--radio-group {
  margin: 20px 0;
}
.register_signup_main_app
  .v-input--radio-group__input
  .v-radio:not(:only-child) {
  margin-bottom: 20px;
}
.register_signup_main_app .v-input--radio-group__input .v-icon {
  font-size: 30px;
}
.register_signup_main_app .v-input--radio-group__input .theme--light.v-label {
  font-size: 20px;
  color: #000000;
}
.register_signup_main_app .v-input--selection-controls .btn-show-detail {
  position: absolute;
  right: 0;
  text-decoration: underline;
  color: #757575;
}
</style>